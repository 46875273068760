import * as React from "react";

const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <h1>Welcome to My Gatsby Website!</h1>
    </main>
  );
};

export default IndexPage;
